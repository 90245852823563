import React from 'react';
import About from './components/About';
import Faq from './components/Faq';
import Features from './components/Features';
import Footer from './components/Footer';
import Homescreen from './components/Homescreen';
import Navbar from './components/Navbar';

import RoadMap from './components/RoadMap';


function App() {
  return (

    <div>
      <Navbar />
      <Homescreen />
      <About />
      <Features />
      <RoadMap />
      <Faq />
      <Footer />
      
       
      

    </div>
  );
}

export default App;
