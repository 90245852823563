import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter as Router, Routes, Route} from 'react-router-dom';
import Tokenamics from './pages/Tokenomics';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <Router>
            <Routes>
                <Route path='/' element={<App />}/>
                <Route path='/tokenamics' element={ <Tokenamics />} />
            </Routes>
    </Router>

);
