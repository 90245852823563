import React from 'react'


import dot from '../images/dot.png'
import illustration from '../images/illustration.png'
import roadmap from '../images/roadmap icon.png'
import rectangle from '../images/Rectangle 9.png'
import Phase from './Phase'

const RoadMap = () => {
  return (
    <section className='w-full sm:py-2 md:py-20 sm:px-5 md:px-16 mx-auto grid md:grid-cols-2'>

        
        <div className='absolute'>
            <img src={dot} alt="" className='w-[60%]'/>
            <img src={roadmap} alt="" className='absolute top-[-25%] left-28'/>
        </div>

        <div className="relative">
            <img src={illustration} alt="" /> 
        </div>
        

        <div className=' sm:px-5 md:ml-[-20%]'>

          <img src={rectangle} alt="" className='pt-20 pb-8'/>

            <h1 className='uppercase text-blue text-2xl py-4 font-semibold'> roadmap</h1>

            <div className='md:ml-[-45%]'>
            <p className='text-deepblue sm:text-center text-sm leading-7'>We are committed to scaling up our operations regularly.</p>
            <p className='text-deepblue sm:text-center text-sm leading-7'>As a result, we've devised a strategy to keep us on our toes</p>
            </div>
        </div>

        <Phase />

       




    </section>
  )
}

export default RoadMap