import React from 'react'

import homeBackground from '../images/guy.png'

import dot from '../images/dot.png'

import rectangle from '../images/Rectangle 9.png'

import icon1 from '../images/icon (1).png'
import icon2 from '../images/icon (4).png'
import icon3 from '../images/icon (5).png'
import icon4 from '../images/icon.png'
import icon5 from '../images/icon (6).png'


import polygon from '../images/Polygon 1.png'


const Homescreen = () => {
  return (
    <section className='w-full py-8 sm:px-5 md:px-16 mx-auto grid md:grid-cols-2'>

        <div className='absolute'>
            <img src={dot} alt="" className='w-[80%]'/>
        </div>

        <div className='relative'>
       
              <div>
                     <img src={homeBackground} alt="" className=' w-[400px] my-4'/>
             </div>

        <div className=''>
                        <img src={icon1} alt="" className='absolute top-[14rem] w-6 left-2'/>
                        <img src={polygon} alt="" className='absolute top-[13rem] w-10'/>
         </div>

         <div>
                <div className="items-center">
                    <img src={polygon} alt="" className='absolute left-[-3%] top-6 w-10'/>
                    <img src={icon2} alt="" className='absolute left-[-1%] top-9 w-4'/>
                </div>
            
            </div>

            <div>

                <div className='items-center'>
                    <img src={polygon} alt="" className='absolute left-16 top-4 w-10' />
                    <img src={icon3} alt="" className='absolute top-7 left-[4.5rem] w-6'/>
                </div>

            </div>

            <div>
                <div className="items-center">
                    <img src={polygon} alt="" className='absolute left-36 top-4 w-10'/>
                    <img src={icon4} alt="" className='absolute left-[9.5rem] top-7 w-6'/>
                </div>
            
            </div>

            <div>
                <div className="items-center">
                   
                    <img src={icon5} alt="" className='absolute left-80 top-10 w-16'/>
                </div>
            
            </div>

        

                   


        </div>

        

           


            <div className='flex flex-col sm:mx-auto sm:mt-10 justify-center max-w-[20rem] ml-20'>


                <h1 className='text-blue uppercase items-center leading-3 sm:text-4xl md:text-4xl'>The Privatixy Token - Providing a wide variety of solutions</h1>

                <img src={rectangle} alt="" className='w-3 py-6' />


                <h3 className='uppercase text-deepblue font-semibold sm:text-2xl md:text-2xl leading-3'>
                for privacy <br /> preservation for <br /> blockchain users
                </h3>
                

                <div className='flex sm:flex-col md:flex-row gap-6 py-10 items-center justify-center'>

                    <button className='sm:w-[60%] px-8 py-3 rounded-3xl bg-darkpurple uppercase text-white text-[8px]'>enter presale</button>
                    
                    <button className='sm:w-[60%] px-8 py-3 rounded-3xl bg-lightpurple uppercase text-white text-[8px]'>read whitepaper</button>
                
                </div>



            </div>





    </section>
  )
}

export default Homescreen