import React from 'react'

import icon1 from '../images/box1.png'
import icon2 from '../images/box2.png'
import icon3 from '../images/box3.png'
import dot from '../images/dotnew.png'


const Features = () => {
  return (
    <section className='mx-auto  sm:px-10 md:px-20 w-full py-10'>

      <h1 className='uppercase text-blue text-2xl py-16 font-bold'>Our Features</h1>

      <div className=''>

    <div class="w-full grid gap-10 sm:grid-cols-1 md:grid-cols-3 mb-14">

      {/* card 1 */}
    
    <div class="flex h-[100%] bg-gradient-to-t from-[#0096ff] to-blue rounded-2xl flex-col items-center mb-10 py-5 relative">

       <img src={icon1} alt="" className='mb-10'/>

        <div className='absolute'>
          <img src={dot} alt="" className='w-full'/>
        </div>

        <div class="flex mt-4 text-center space-x-3 lg:mt-6">
        <h5 class="mb-1 text-[13px] capitalize font-medium text-white px-4">Decentralized <br /> technology adoption and <br /> usability</h5>
        </div>
    </div>

          {/* card 2 */}
    <div class="flex flex-col h-[100%] items-center bg-gradient-to-t from-deepblue to-blue relative rounded-2xl mb-10 py-5">
       <img src={icon2} alt="" className='mb-5'/>

       <div className='absolute'>
          <img src={dot} alt="" className='w-full '/>
        </div>
      
       
        <div class="flex mt-4 text-center space-x-3 lg:mt-6">
        <h5 class="mb-1 text-[12px] capitalize font-medium text-white px-4">Access to contracts that <br /> offer encrypted input, state, and output, <br /> allowing for a wide range of design and <br /> implementation options.</h5>
        </div>
    </div>

    {/* card 3 */}

    <div class="flex flex-col h-[100%] bg-gradient-to-t from-deepblue to-blue items-center mb-10 py-5 relative rounded-2xl">
       <img src={icon3} alt="" className='mb-5'/>

       <div className='absolute'>
          <img src={dot} alt="" className='w-full'/>
        </div>
      
       
        <div class="flex mt-4 text-center space-x-3 lg:mt-6">
        <h5 class="mb-1 text-[13px] capitalize font-medium text-white px-4">Join our community of <br /> users from all around the world!</h5>
        </div>
    </div>

    

    </div>

    </div>

    </section>

  )
}

export default Features
