import React from 'react'

// image 

import union from '../images/union.png'
import rectangle from '../images/Rectangle 9.png'

const About = () => {
  return (
    <section className='mt-5 w-full sm:px-5 md:px-16 '>

            <div className='sm:hidden md:block'>
            <h1 className='uppercase text-darkpurple my-7 font-bold text-2xl'>about privatixy</h1>
            </div>
        <div className=' mx-auto grid md:grid-cols-2 gap-10'>
            

             <div className='details max-w-[30rem] sm:order-2 md:order-1'>

                <p className='text-[15px] text-deepblue leading-7 font-normal'> Privatixy Protocol is an open-source protocol that offers a wide variety of solutions for privacy preservation for blockchain users. Individuals will be able to retain privacy and property control while having easy access to their identities and other essential data, thanks to zero-knowledge proofs.</p>

                <div className='mt-20'>
                    <img src={rectangle} alt="" />
                </div>
             </div>

           <div className='mx-auto sm:order-1 md:order-3'>
           <img src={union} alt="" className='w-[100%]'/>
           </div>

                <h4 className='sm:order-1 uppercase text-darkpurple my-7 font-bold md:hidden '>
                About Privatixy
                </h4>
        </div>
        

    </section>
  )
}

export default About
