import React from 'react'
import Navbar from '../components/Navbar'

// images

import dot from '../images/dottoken.png'

import pieBackground from '../images/pie.png'
import rectangle from '../images/Rectangle 9.png'
import ellipse from '../images/Ellipse 18.png'


import percentage from '../images/percentage.png'
import percentage2 from '../images/percentage2.png'
import hand from '../images/hand.png'
import rewardsdots from '../images/rewardsdot.png'


import Cards from './Cards'
import Footer from '../components/Footer'




const Tokenomics = () => {
  return (
    <section>
      <Navbar />

      <div className='w-full py-8 sm:px-5 md:px-16 mx-auto grid md:grid-cols-2'>

      <div className='absolute'>
            <img src={dot} alt="" className='w-[80%]'/>
        </div>

        <div className="relative">
              <div>
                     <img src={pieBackground} alt="" className=' w-[400px] my-4'/>
             </div>

        </div>


      <div className='details flex flex-col sm:mx-auto sm:mt-10 justify-center max-w-[20rem] ml-20'>

        <h1 className='text-blue uppercase items-center font-semibold leading-3 sm:text-4xl md:text-4xl'>Tokenomics</h1>

        <img src={rectangle} alt="" className='w-3 py-6' />

        <div>
            <h3 className='lowercase text-deepblue  font-normal text-md '>The total supply of the Privatixy Protocol Tokens is <span className='text-pink-500 uppercase '>60M</span> as it is distributed as follows:</h3>


        </div>
        <p className='text-deepblue mt-24 text-sm sm:hidden md:block'>Use of Proceeds and Funds from Token Sales</p>

     </div>  

      </div>


      {/* chart info */}
    <div className='grid sm:grid-cols-1 md:grid-cols-2 w-full h-full mb-10 px-5'>
      <div className='flex sm:flex-col relative md:flex-row mt-10 justify-between sm:gap-10 md:gap-20 items-center w-full px-40'>
        <div className='right relative'>
          <div>
               
            <img src={ellipse} alt="" className='absolute opacity-80 top-20 w-72'/>
            <img src={percentage} alt="" className='sm:w-full md:w-72'/> 
          </div>

      <div className='sm:mt-10 md:mt-20'>
          <ol class="relative border-l border-pink-500">                  
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-blue rounded-full -left-1.5 border border-blue "></div>
                
                <h3 class="capitalize text-sm text-darkpurple">15% <span className='text-black'>(9,000,000) Team</span></h3>
              
            </li>

            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-blue rounded-full -left-1.5 border border-blue "></div>
                
                <h3 class="capitalize text-sm text-darkpurple">15% <span className='text-black'>(3,000,000) Advisors</span></h3>
              
            </li>
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-blue rounded-full -left-1.5 border border-blue "></div>
                
                <h3 class="capitalize text-sm text-darkpurple">15% <span className='text-black'>(3,000,000) Liquidity</span></h3>
              
            </li>
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-pink-700 rounded-full -left-1.5 border border-pink-700 "></div>
                
                <h3 class="capitalize text-sm text-pink-700">15% <span className='text-black'>(9,000,000) Company Reserves</span></h3>
              
            </li>
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-pink-700 rounded-full -left-1.5 border border-pink-700 "></div>
                
                <h3 class="capitalize text-sm text-pink-700">25%  <span className='text-black'>(15,000,000) Public Sale</span></h3>
              
            </li>
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-pink-600 rounded-full -left-1.5 border border-pink-600 "></div>
                
                <h3 class="capitalize text-sm text-pink-600">25%  <span className='text-black'>(15,000,000) Private Sale</span></h3>
              
            </li>
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-pink-600 rounded-full -left-1.5 border border-pink-600 "></div>
                
                <h3 class="capitalize text-sm text-pink-600">10%  <span className='text-black'>(6,000,000) Strategic Partners</span></h3>
              
            </li>
            
            
            
        </ol>
        </div>
    

        </div>
          
      </div>

     



    <div className='flex sm:flex-col md:flex-row justify-between sm:gap-10 md:gap-20 items-center w-full px-20'>
    <div className='right relative'>
          <div>
               
            <img src={ellipse} alt="" className='absolute opacity-80 top-20 w-72'/>
            <img src={percentage2} alt="" className='sm:w-full md:w-72'/> 
          </div>

      <div className='sm:mt-10 md:mt-40'>
          <ol class="relative border-l border-pink-500">                  
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-blue rounded-full -left-1.5 border border-blue "></div>
                
                <h3 class="capitalize text-sm text-darkpurple">50% <span className='text-black'>Product Research and Development</span></h3>
              
            </li>

            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-blue rounded-full -left-1.5 border border-blue "></div>
                
                <h3 class="capitalize text-sm text-darkpurple">20% <span className='text-black'>Sales & Marketing</span></h3>
              
            </li>
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-blue rounded-full -left-1.5 border border-blue "></div>
                
                <h3 class="capitalize text-sm text-darkpurple">10% <span className='text-black'>Reserves & Liquidity</span></h3>
              
            </li>
            <li class="mb-10 ml-20">
                <div class="absolute w-3 h-3 bg-pink-700 rounded-full -left-1.5 border border-pink-700 "></div>
                
                <h3 class="capitalize text-sm text-pink-700">5% <span className='text-black'>legal</span></h3>
              
            </li>
          
        </ol>
        </div>
    

        </div>
    </div>   

    <img src={rectangle} alt="" className='w-3 py-6 ml-28' />

    </div>

    {/* rewards */}

      <div className='w-full py-8 sm:px-5 md:px-32 mx-auto grid md:grid-cols-2'>

      <div className='image relative md:hidden'>
            <img src={rewardsdots} alt="" className='absolute w-full'/>

            <div className=''>
            <img src={hand} alt="" className='sm:ml-10 md:ml-44'/>
            </div>
        </div>

        <div className='details py-4'>
          <h1 className='text-blue uppercase font-semibold text-2xl'>Rewards</h1>
          <p className='py-4 text-sm text-deepblue' >Token holders will also get rewarded when they provide <br /> liquidity on the DEXs in which Privatixy Protocol token is <br /> available or when they stake Privatixy Protocol tokens.</p>
        
        </div>

          {/* image */}
        <div className='image relative sm:hidden md:block'>
            <img src={rewardsdots} alt="" className='absolute w-full'/>

            <div className=''>
            <img src={hand} alt="" className='ml-44'/>
            </div>
        </div>

         <img src={rectangle} alt="" className='w-3 py-6 ml-0' />


      </div>


      {/* team allocation */}

      <div className='w-full py-8 sm:px-5 md:px-32 '>
        <h1 className='text-blue font-semibold text-2xl uppercase py-4'>Token allocation</h1>


        <p className='text-deepblue text-sm '>We will reserve a percentage of Privatixy Protocol <br/> tokens and allocate them towards the <br /> fulfilment of the following responsibilities:</p>
      </div>

      <Cards />

      <Footer />

      


    </section>
  )
}

export default Tokenomics