import React from 'react'





const Phase = () => {
  return (
    <section className='sm:mt-10 md:mt-36 px-16'>

        <ol class="relative border-l border-blue">                  
            <li class="mb-10 sm:ml-10 md:ml-20">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full  -left-1.5 border border-blue "></div>
                
                <h3 class="text-lg font-semibold uppercase text-darkpurple">Phase I : Creation of Website</h3>
                <p class="mb-4 text-[14px] py-4 font-normal text-deepblue ">The first stage will be dedicated to developing and establishing the Privatixy Protocol official website.</p>
               
            </li>
            <li class="mb-10 sm:ml-10 md:ml-20">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-blue "></div>
                
                <h3 class="text-lg font-semibold uppercase text-darkpurple">Phase II : Release of Whitepaper</h3>
                <p class="mb-4 text-[14px] py-4 font-normal text-deepblue ">The release and publication of project documentation will be the main focus of the following stage.  This document provides all of the pertinent project information.</p>
               
            </li>

            <li class="mb-10 sm:ml-10 md:ml-20">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-blue "></div>
                
                <h3 class="text-lg font-semibold uppercase text-darkpurple">Phase III : Private Sale</h3>
                <p class="mb-4 text-[14px] py-4 font-normal text-deepblue ">Limited investors and other interested parties can invest in the project at this stage.</p>
               
            </li>
            <li class="mb-10 sm:ml-10 md:ml-20">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-blue "></div>
                
                <h3 class="text-lg font-semibold uppercase text-darkpurple">Phase IV : Public Sale</h3>
                <p class="mb-4 text-[14px] py-4 font-normal text-deepblue ">Access to the purchase of the Privatixy Protocol token.</p>
               
            </li>
            <li class="mb-10 sm:ml-10 md:ml-20">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-blue "></div>
                
                <h3 class="text-lg font-semibold uppercase text-darkpurple">Phase V : Exchange Listing</h3>
                <p class="mb-4 text-[14px] py-4 font-normal text-deepblue ">The native tokens will be listed on major centralized and decentralized exchanges.</p>
               
            </li>
            <li class="mb-10 sm:ml-10 md:ml-20">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-blue "></div>
                
                <h3 class="text-lg font-semibold uppercase text-darkpurple">Phase IX : Marketing</h3>
                <p class="mb-4 text-[14px] py-4 font-normal text-deepblue ">To raise awareness of the protocol, extensive marketing will be conducted. We will utilize a variety of marketing techniques, including enlisting the assistance of prominent cryptocurrency influencers.</p>
               
            </li>

            <li class="mb-10 sm:ml-10 md:ml-20">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full  -left-1.5 border border-blue "></div>
                
                <h3 class="text-lg font-semibold uppercase text-darkpurple">Phase X : Privatixy Protocol Launch</h3>
              
            </li>
            
        </ol>

            

        

    </section>
  )
}

export default Phase