import React from 'react'


// images
import rectangle from '../images/Rectangle 9.png'
import dot from '../images/dot.png'

import illustration2 from '../images/illustration2.png'



const Faq = () => {
  return (
    <div>
    <div className='grid sm:grid-cols-1 md:grid-cols-2 w-full h-full mb-10 px-5'>
        
        <div className='sm:px-5'>

        <img src={rectangle} alt="" className='pt-20 sm:ml-0 md:ml-10 pb-8'/>

        <h1 className='uppercase text-blue text-2xl py-4 font-semibold sm:hidden px-4'>faqs</h1>

        </div>

            <div className='ml-20 relative'>
              <img src={dot} alt="" className='absolute h-full w-full'/>
            <img src={illustration2} alt="" className='object-cover md:ml-32'/>
            </div>

        <h1 className='uppercase text-blue text-2xl py-4 font-semibold mt-10 sm:px-2 md:px-14'>faqs</h1>


         

          </div>

          <div className='flex sm:flex-col md:flex-row justify-between sm:gap-10 md:gap-20 items-center w-full sm:px-5 md:px-20'>

            <div>
            <h1 className='text-blue uppercase py-2 border rounded-2xl border-blue px-5 font-bold mb-6'>What is Privatixy Protocol?</h1>
            <p className='text-deepblue text-sm leading-7 max-w-[30rem]'>Privatixy Protocol is an open-source protocol that offers a wide variety of solutions for privacy preservation for blockchain users.</p>
            </div>

            <div>
              <h1 className='text-blue uppercase py-2 border rounded-2xl border-blue px-5 font-bold mb-6'>As Privatixy Protocol is an open-source <br/> protocol, does that mean it is free?</h1>
              <p className='text-deepblue text-sm leading-7 max-w-[30rem]'>Yes. It is free in the sense that it gives freedom to those who use it.</p>
            </div>

            </div>



            <div className='flex sm:flex-col md:flex-row justify-between items-center sm:gap-10 md:gap-20 w-full sm:px-5 md:px-20 mt-10'>

            <div>
            <h1  className='text-blue uppercase py-2 border rounded-2xl border-blue px-5 font-bold mb-6'>Is Privatixy Protocol an <br/> open-source protocol?</h1>
            <p className='text-deepblue text-sm leading-7 max-w-[30rem]'>Privatixy Protocol is an open-source protocol that offers a wide variety of solutions for privacy preservation.  It aims to adopt decentralized technology which means it is a very public, transparent method of storing data.</p>
            </div>

            <div>
              <h1  className='text-blue uppercase py-2 border rounded-2xl border-blue px-5 font-bold mb-6'>Why is privacy preservation <br/> important?</h1>
              <p className='text-deepblue text-sm leading-7 max-w-[30rem]'>It is important because it gives freedom to users. When blockchain users have more control over their data, they may choose who their data is shared with and for what purposes.</p>
            </div>

            </div>



        </div>
  )
}

export default Faq