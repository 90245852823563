import React, {useState} from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

import { Link } from 'react-router-dom'

// logo
import logo from '../images/logo.png'




const Navbar = () => {

  const [nav,setNav] = useState(false)

  const handleNav = () => {
      setNav(!nav)
  }
  
  return (
    <nav className='flex justify-between items-center h-20 sm:px-5 md:px-24 mx-auto w-full py-5'>

        {/* logo */}
      <Link to='/'><img src={logo} alt="" className='w-10'/></Link>

        {/* nav links desktop */}
      <ul className='hidden md:flex uppercase  transition-all text-[14px] font-normal leading-3 text-[#000b17]'>
           <Link to='/'><li className='p-4 hover:text-gray-600 cursor-pointer'>home</li></Link>
            <Link to= '/'><li className='p-4 hover:text-gray-600 cursor-pointer'>about</li></Link>
            <Link to= '/'><li className='p-4 hover:text-gray-600 cursor-pointer'>features</li></Link>
            <Link to= '/'> <li className='p-4 hover:text-gray-600 cursor-pointer'>roadmap</li></Link>
            <Link to='/tokenamics'><li className='p-4 hover:text-gray-600 cursor-pointer'>tokenomics</li></Link>
            <Link to='/'><li className='p-4 hover:text-gray-600 cursor-pointer'>faqs</li></Link>
        </ul>


        
        {/*  navigation */}

        <div onClick={handleNav} className='block z-10000 md:hidden text-dark'>
           {nav ? <AiOutlineClose size={25} /> :  <AiOutlineMenu size={25} /> }
          
        </div>


         {/*  mobile drop down */}

         <div className={nav ? 'fixed px-5 py-5 left-0 top-0 bg-closed w-full border-r-gray-900 h-full ease-in-out duration-500 z-50' : 'fixed left-[-100%]' }>


         <img src={logo} alt="" className='w-10'/>

            <ul className='pt-20 uppercase p-4 text-center'>
            <Link to='/'><li className='p-4 cursor-pointer'>home</li></Link>
            <Link to= '/'><li className='p-4 cursor-pointer'>about</li></Link>
            <Link to= '/'><li className='p-4 cursor-pointer'>features</li></Link>
            <Link to= '/'> <li className='p-4 cursor-pointer'>roadmap</li></Link>
            <Link to='/tokenamics'><li className='p-4 cursor-pointer'>tokenomics</li></Link>
            <Link to='/'><li className='p-4 cursor-pointer'>faqs</li></Link>
            </ul>




         </div>


    </nav>
  )
}

export default Navbar