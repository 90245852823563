import React from 'react'

// images

import icon1 from '../images/icon (7).png'
import icon2 from '../images/icon (8).png'
import icon3 from '../images/icon (9).png'
import icon4 from '../images/icon (10).png'
import icon5 from '../images/icon (11).png'

import smalldots from '../images/smalldots.png'


const Cards = () => {
  return (

    <section className='mx-auto sm:px-16 md:px-20 mt-10'>


    
    <div class="w-full grid gap-10 sm:grid-cols-1 md:grid-cols-5">

      {/* card 1 */}
    
    <div class="flex bg-[#3E1FE7] rounded-2xl flex-col items-center mb-10 py-5 relative">

       <img src={icon1} alt="" />

        <div className='absolute'>
          <img src={smalldots} alt="" className='w-full'/>
        </div>

        <div class="flex mt-4 text-center space-x-3 lg:mt-6">
        <h5 class="mb-1 text-[15px] capitalize font-medium text-white ">future <br /> initiatives</h5>
        </div>
    </div>

          {/* card 2 */}
    <div class="flex flex-col items-center bg-gradient-to-t from-blue to-purple-700 relative rounded-2xl mb-10 py-5">
       <img src={icon2} alt="" className='mb-5'/>

       <div className='absolute'>
          <img src={smalldots} alt="" className='w-full'/>
        </div>
      
       
        <div class="flex mt-4 text-center space-x-3 lg:mt-6">
        <h5 class="mb-1 text-[15px] capitalize font-medium text-white ">Community <br />support </h5>
        </div>
    </div>

    {/* card 3 */}

    <div class="flex flex-col bg-gradient-to-t from-purple-700 to-purple-600 items-center mb-10 py-5 relative rounded-2xl">
       <img src={icon3} alt="" className='mb-10'/>

       <div className='absolute'>
          <img src={smalldots} alt="" className='w-full'/>
        </div>
      
       
        <div class="flex mt-4 text-center space-x-3 lg:mt-6">
        <h5 class="mb-1 text-[15px] capitalize font-medium text-white ">Marketing</h5>
        </div>
    </div>

    {/* card 4 */}

    <div class="flex flex-col bg-purple-700 rounded-2xl items-center relative mb-10 py-5">
       <img src={icon4} alt="" className='mb-5'/>
       <div className='absolute'>
          <img src={smalldots} alt="" className='w-full'/>
        </div>
      
       
        <div class="flex mt-4 text-center space-x-3 lg:mt-6">
        <h5 class="mb-1 text-[15px] capitalize font-medium text-white ">Exchanging <br /> fees </h5>
        </div>
    </div>

    {/* card 5 */}

    <div class="flex flex-col bg-pink-500 rounded-2xl items-center mb-10 relative py-5">
       <img src={icon5} alt="" className='mb-5'/>
       <div className='absolute'>
          <img src={smalldots} alt="" className='w-full'/>
        </div>
      
       
        <div class="flex mt-4 text-center space-x-3 lg:mt-6">
        <h5 class="mb-1 text-[15px] capitalize font-medium text-white ">long-term <br /> liqiudity</h5>
        </div>
    </div>



    </div>

    </section>
  )
}

export default Cards