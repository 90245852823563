import React from 'react'
import { FaFacebook,  FaInstagram,  FaReddit,  FaTelegram,  FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import logo from '../images/logofooter.png'
import vector from '../images/Vector.png'

const Footer = () => {
  return (
    <footer className='footer flex flex-col w-full h-[-30%] mt-40 bg-blue relative '>

        <div className=' '>
            <img src={vector} alt="" className='w-full h-[30%]'/>
        </div>
        
     
    <div className='mt-[-15%]'>
        <div className="logo flex items-center justify-center py-6">
            <img src={logo} alt="" />
        </div>
        <div className="links">
            <ul className='flex sm:flex-col md:flex-row items-center justify-center gap-5 uppercase text-white text-center text-sm mt-3 mb-6 '>
                <Link to='/'><li  className='cursor-pointer'>home</li></Link>
                <li>about</li>
                <li>features</li>
                <li>roadmap</li>
                <Link to='/tokenamics' className='cursor-pointer'>Tokenomics</Link>
                <li>faq</li>
            </ul>
        </div>

        <div className="socials flex sm:flex-col md:flex-row items-center mt-8 justify-center gap-28 mb-6">
            <h4 className='uppercase text-[#f8f8ff]'>connect with us on social media</h4>
            <div className='text-white flex gap-5  cursor-pointer'>
            <FaFacebook size={30}/>
            <FaReddit size={30}/>
            <FaTwitter size={30}/>
            <FaTelegram size={30}/>
            <FaInstagram size={30}/>
            </div>
        </div>

        <div className='credits flex items-center justify-center uppercase text-white text-sm py-5 mb-10'>
            <p>© 2022 Privatixy Protocol. All rights reserved.</p>
        </div>
    
    </div>

    </footer>
  )
}

export default Footer